<template>
  <div class="input-price">
    <div class="input-price-price">
      <input
        type="text"
        v-model="value.price"
        @focus="selectAll"
        @input="
          value.price = $event.target.value.replace(/\D/g, '').substring(0, 6)
        "
      />
      <div class="input-price-endfix">만</div>
    </div>
    <select class="input-price-select" v-model="value.won_type">
      <option :value="0">원</option>
      <option :value="1">원~</option>
      <option :value="2">원 초반대</option>
      <option :value="3">원 후반대</option>
    </select>
  </div>
</template>
<script>
export default {
  name: "InputPrice",
  components: {},
  mixins: [],
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          price: 0,
          won_type: 0,
        };
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    selectAll(e) {
      e.target.select();
    },
  },
};
</script>
<style lang="stylus" scoped>
.input-price
  display flex
  align-items center
  gap 10px
input
select
  border 1px solid #e3e3e3
  border-radius 8px
  padding 12px 16px
  outline none
  font-size 15px
  color #333
  option
    color #333
    font-size 15px
.input-price-price
  position relative
input
  max-width 240px
  text-align right
  padding-right 32px
.input-price-endfix
  position absolute
  font-size 15px
  right 16px
  top 50%
  transform translateY(-50%)
  color #333

@media (max-width: 768px)
  .input-price
    max-width 100%
</style>
